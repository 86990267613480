import moment from 'moment'
import { mdy } from './../../util/dates'

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function getAmountFormatted(cell, row) {
  if (cell === 0) return 'N/A'
  return `$${cell.toFixed(2)} ${row.currency}`
}

export const columns = [
  {
    dataField: 'paypal.data.orderID',
    text: 'Order ID',
    sort: true
  },
  {
    dataField: 'amount',
    text: 'Monto',
    sort: true,
    formatter: getAmountFormatted
  },
  {
    dataField: 'campaign.title',
    text: 'Campaña',
    sort: true
  },
  {
    dataField: 'paypal.details.purchase_units[0].shipping.name.full_name',
    text: 'Donador',
    sort: true
  },
  {
    dataField: 'paypal.details.purchase_units[0].payee.email_address',
    text: 'Correo',
    sort: true,
    formatter: cell => cell || 'Anónimo'
  },
  {
    dataField: 'createdAt',
    text: 'Fecha',
    sort: true,
    formatter: mdy
  }
]

export const columns2 = [
  {
    dataField: 'paypal.data.orderID',
    text: 'Order ID',
    sort: true
  },
  {
    dataField: 'amount',
    text: 'Monto',
    sort: true,
    formatter: getAmountFormatted
  },
  {
    dataField: 'campaign[0].title',
    text: 'Campaña',
    sort: true
  },
  {
    dataField: 'paypal.details.purchase_units[0].shipping.name.full_name',
    text: 'Donador',
    sort: true
  },
  {
    dataField: 'paypal.details.purchase_units[0].payee.email_address',
    text: 'Correo',
    sort: true,
    formatter: cell => cell || 'Anónimo'
  },
  {
    dataField: 'createdAt',
    text: 'Fecha',
    sort: true,
    formatter: mdy
  }
]

// Filtra todas las donaciones activas y aprobadas
// devuele el total de su sumatoria.
export const sum = donations =>
  donations.filter(d => d.approved).reduce((p, c) => p + c.amount, 0)

export const splitDate = date => (date ? date.split('T')[0] : date)

// Función para obtener el total del monto de donaciones divididas por tipo
// @params: donations (Objeto con propiedades que representan los tipos de donaciones)
export function getTotalAmount(donations) {
  const keys = Object.keys(donations)
  let result = {}
  keys.forEach(type => {
    result[type] = sum(donations[type])
  })
  return result
}

// Función que toma dos parametros, list y param, el primero debe ser un arreglo de objetos,
// el segundo la propiedad cuyo valor será el que regirá el agrupamiento de los elementos de la lista.
export const groupBy = (list, param) => {
  const result = list.reduce((a, b) => {
    let property = b[param]
    if (Object.prototype.hasOwnProperty.call(a, property)) a[property].push(b)
    else a[property] = [b]
    return a
  }, {})
  return result
}

// Filter donations approved
const donationApproved = d => d.approved

// Devuelve la fecha
const getdonationDates = donation => donation.createdAt

// Acomoda las fechas
const dateSortAsc = function (date1, date2) {
  // This is a comparison function that will result in dates being sorted in
  // ASCENDING order. As you can see, JavaScript's native comparison operators
  // can be used to compare dates. This was news to me.
  if (date1 > date2) return 1
  if (date1 < date2) return -1
  return 0
}

// Remover duplicados de un array
const removeDuplicates = arr => [...new Set(arr)]

// Acomoda las fechas
const dateSortAscProp = function (date1, date2) {
  // This is a comparison function that will result in dates being sorted in
  // ASCENDING order. As you can see, JavaScript's native comparison operators
  // can be used to compare dates. This was news to me.
  if (date1.createdAt > date2.createdAt) return 1
  if (date1.createdAt < date2.createdAt) return -1
  return 0
}

// formatea la fecha a string o el parametro de funcion que pase
// de objeto
const splitdateArray = splitDate => d => ({
  type: d.type,
  amount: d.amount,
  approved: d.approved,
  _id: d._id,
  createdAt: splitDate(d.createdAt)
})

// suma los montos por fecha
const sumBydate = (pre, current) => {
  let i = pre.findIndex(p => p.createdAt === current.createdAt)
  if (i === -1) {
    return [...pre, current]
  }
  pre[i].amount += current.amount
  return pre
}

const dateFormatted = date => moment(date).format('MM/DD/YYYY')

// devuelve el amount
const getAmount = data => date => {
  let d = data.find(p => dateFormatted(p.createdAt) === date)
  return d ? d.amount : 0
}

export const donationHistoricalChart = donations => {
  // 1 Obtener las fechas de las donacions aprobadas
  const dates = donations.filter(donationApproved).map(getdonationDates)
  // 2 Acomodarlas fechas
  const sortedDates = dates.sort(dateSortAsc)
  // 3 Formatear las fechas
  const formattedDates = sortedDates.map(splitDate).map(dateFormatted)
  // 4 Remover duplicados
  const labels = removeDuplicates(formattedDates)
  // 5 Obtener array de objectos de donaciones aprovadas
  const preprocessed = donations.filter(donationApproved)
  // 6 Ordenar el array de objetos por fecha de donacion
  const dataPaypal = preprocessed
    .filter(d => d.type === 'paypal')
    .sort(dateSortAscProp)
    .map(splitdateArray(splitDate))
    .reduce(sumBydate, [])

  const line = {
    labels,
    datasets: [
      {
        label: 'Donaciones PayPal (MXN)',
        fillColor: 'rgba(66,139,202,0.5)',
        backgroundColor: 'rgba(66,139,202,0.5)',
        strokeColor: 'rgba(66,139,202,1)',
        pointColor: 'rgba(66,139,202,1)',
        pointStrokeColor: 'rgba(0,0,220, 0.2)',
        pointHighlightFill: 'rgba(0,0,220, 0.2)',
        pointHighlightStroke: 'rgba(66,139,202,1)',
        data: labels.map(getAmount(dataPaypal))
      }
    ]
  }

  return line
}

export function donationTypesChart(donations) {
  const types = getTotalAmount(groupBy(donations, 'type'))
  const pie = {
    datasets: [
      {
        data: [types['paypal']],
        backgroundColor: ['rgba(66,139,202,0.8)']
      }
    ],
    labels: ['PayPal']
  }

  return pie
}

export const donationHistoricalAnonymousChart = donations => {
  // 1 Obtener las fechas de las donacions aprobadas
  const dates = donations.filter(donationApproved).map(getdonationDates)
  // 2 Acomodarlas fechas
  const sortedDates = dates.sort(dateSortAsc)
  // 3 Formatear las fechas
  const formattedDates = sortedDates.map(splitDate).map(dateFormatted)
  // 4 Remover duplicados
  const labels = removeDuplicates(formattedDates)
  // 5 Obtener array de objectos de donaciones aprovadas
  const preprocessed = donations.filter(donationApproved)
  // 6 Ordenar el array de objetos por fecha de donacion
  const anonymous = preprocessed
    .filter(d => d.createdBy === null)
    .sort(dateSortAscProp)
    .map(splitdateArray(splitDate))
    .reduce(sumBydate, [])

  const line = {
    labels,
    datasets: [
      {
        label: 'Donaciones Anónimas (MXN)',
        fillColor: 'rgba(66,139,202,0.5)',
        backgroundColor: 'rgba(66,139,202,0.5)',
        strokeColor: 'rgba(66,139,202,1)',
        pointColor: 'rgba(66,139,202,1)',
        pointStrokeColor: 'rgba(0,0,220, 0.2)',
        pointHighlightFill: 'rgba(0,0,220, 0.2)',
        pointHighlightStroke: 'rgba(66,139,202,1)',
        data: labels.map(getAmount(anonymous))
      }
    ]
  }

  return line
}

export function donationLineAndPieChart(donations, list) {
  const line = donationHistoricalChart(donations)
  const pie = donationTypesChart(list)
  const anonymous = donationHistoricalAnonymousChart(donations)
  return { pie, line, anonymous }
}
