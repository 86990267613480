import React from 'react'
import { useSelector } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'

function Footer() {
	const configuration = useSelector((state) => state.configuration.public)
	const name = configuration.settings.name

	return (
		<footer className="ftco-footer ftco-bg-dark ftco-section">
			<div className="container">
				<div className="row">
					<div className="col-md d-lg-block d-md-none">
						<div className="ftco-footer-widget mb-4 bg-primary py-4 px-3">
							<h2 className="ftco-heading-2">{name}</h2>
							<p>Tu donación hace la diferencia.</p>
							<ul className="ftco-footer-social list-unstyled mb-0">
								<li>
									{configuration.urls.twitter && (
										<a href={configuration.urls.twitter}>
											<span className="icon-twitter" />
										</a>
									)}
								</li>
								<li>
									{configuration.urls.facebook && (
										<a href={configuration.urls.facebook}>
											<span className="icon-facebook" />
										</a>
									)}
								</li>
								<li>
									{configuration.urls.instagram && (
										<a href={configuration.urls.instagram}>
											<span className="icon-instagram" />
										</a>
									)}
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md d-sm-none d-lg-block">
						<div className="ftco-footer-widget mb-4 ml-md-5">
							<h2 className="ftco-heading-2">Secciones</h2>
							<ul className="list-unstyled">
								<li>
									<Link to="/about#content" className="py-2 d-block">
										Nosotros
									</Link>
								</li>
								<li>
									<Link to="/campaigns#content" className="py-2 d-block">
										Campañas
									</Link>
								</li>
								{/* <li>
                  <Link to='/contact' className='py-2 d-block'>
                    Contacto
                  </Link>
                </li> */}
								<li>
									<Link to="/login#content" className="py-2 d-block">
										Inicio de sesión
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md">
						<div className="ftco-footer-widget mb-4">
							<h2 className="ftco-heading-2">Legal</h2>
							<ul className="list-unstyled">
								<li>
									<Link to="/notice-of-privacy#content" className="py-2 d-block">
										Aviso de privacidad
									</Link>
								</li>
								<li>
									<Link to="/terms-and-conditions#content" className="py-2 d-block">
										Términos y condiciones
									</Link>
								</li>
								<li className="py-2 text-white">
									<span>&copy; Copyright {new Date().getFullYear()}</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md">
						<div className="ftco-footer-widget mb-4">
							<h2 className="ftco-heading-2">Información de contacto</h2>
							<div className="block-23 mb-3">
								<ul>
									<li>
										<span className="icon icon-map-marker" />
										<span className="text">{configuration.contact.address}</span>
									</li>
									<li>
										<a href={'tel:' + configuration.contact.phone}>
											<span className="icon icon-phone" />
											<span className="text">{configuration.contact.phone}</span>
										</a>
									</li>
									<li>
										<a href={'mailto:' + configuration.contact.email}>
											<span className="icon icon-envelope my-1" />
											<span className="text-desk">{configuration.contact.email}</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="row">
          <div className="col-md-12 text-center">

            <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
  Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>
  <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></p>
          </div>
        </div> */}
			</div>
		</footer>
	)
}

export default Footer
